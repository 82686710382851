<template lang="pug">
  v-row
    v-col(cols="12")
      filter-terminals(
        :queryList="() => terminals('getTerminals')"
      )
    v-col(cols="12")
      list-terminals(
        :queryList="() => terminals('getTerminalsScroll')"
      )
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Terminals',

  components: {
    FilterTerminals: () => import('./list/Filters.vue'),
    ListTerminals: () => import('./list/List.vue'),
  },

  methods: {
    ...mapActions('TERMINALS_LIST', {
      getTerminals: 'queryListFilter',
      getTerminalsScroll: 'queryListScroll',
    }),

    terminals(type) {
      this[type]()
        .then(data =>
          this.$notify({
            group: 'note',
            type: 'info',
            title: `Показано терминалов: ${this.$store.getters['TERMINALS_LIST/terminalsCount']}`,
            text: '',
          }),
        )
        .catch(e => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: `Произошла ошибка загрузки терминалов. Попробуйте позже.`,
            text: '',
          });
        });
    },
  },
};
</script>
